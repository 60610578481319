<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" md="12" sm="12" xs="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="6" md="6" sm="6" xs="12">
                      <ValidationProvider name="Date" vid="date" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                          </template>
                            <flat-pickr class="form-control"
                              :config="{ static: true }"
                              v-model="form.date"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :class="errors[0] ? 'is-invalid' : ''"
                            ></flat-pickr>
                            <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="6" xs="12">
                      <ValidationProvider name="Attachment" vid="attachment">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('globalTrans.attachment') }}
                            <a v-if="id && form.attachment" :href="authServiceBaseUrl + 'download-attachment?file=' + form.attachment">
                              <i class="ri-download-cloud-line"></i>
                            </a>
                          </template>
                          <b-form-file
                              id="attachment"
                              ref="attachment"
                              @change="onAttachmentChange"
                              accept=".pdf,.doc,.docx"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" md="6" sm="6" xs="12">
                      <ValidationProvider name="Location (En)" vid='location_en' rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="location_en"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('authentication.location_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="location_en"
                            v-model="form.location_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="6" xs="12">
                      <ValidationProvider name="Location (Bn)" vid='location_bn' rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="location_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('authentication.location_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="location_bn"
                            v-model="form.location_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" md="12" sm="12" xs="12">
                      <ValidationProvider name="Tour Details (En)" vid="tour_details_en" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="2"
                            label-for="tour_details_en"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('authentication.tour_details_en') }}
                            </template>
                            <b-form-textarea
                            id="tour_details_en"
                            v-model="form.tour_details_en"
                            rows="1"
                            max-rows="2"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" md="12" sm="12" xs="12">
                      <ValidationProvider name="Tour Details (Bn)" vid="tour_details_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="2"
                            label-for="tour_details_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('authentication.tour_details_bn') }}
                            </template>
                            <b-form-textarea
                            id="tour_details_bn"
                            v-model="form.tour_details_bn"
                            rows="1"
                            max-rows="2"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl } from '../../../../../config/api_config'
import { movementStore, movementUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.form = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      authServiceBaseUrl: authServiceBaseUrl,
      loading: false,
      form: {
        org_id: 0,
        office_type_id: 0,
        office_id: 0,
        designation_id: 0,
        user_id: 0,
        date: '',
        location_en: '',
        location_bn: '',
        tour_detils_en: '',
        tour_detils_bn: '',
        attachment: null,
        update_attachment: null
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.orgList.filter(item => item.status === 0)
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  methods: {
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onAttachmentChange (e) {
      const input = e.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
            if (this.id) {
              this.form.update_attachment = e.target.result
            } else {
              this.form.attachment = e.target.result
            }
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.form.attachment = null
      }
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      this.form.org_id = this.authUser.org_id
      this.form.office_type_id = this.authUser.office_type_id
      this.form.office_id = this.authUser.office_id
      this.form.designation_id = this.authUser.designation_id
      this.form.user_id = this.authUser.user_id

      if (this.form.id) {
        result = await RestApi.putData(authServiceBaseUrl, `${movementUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(authServiceBaseUrl, movementStore, this.form)
      }

      this.loading = false
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
