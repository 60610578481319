<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user.movement_summary') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="6" md="4" lg="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.from_date')"
                    label-for="from_date"
                    >
                      <flat-pickr class="form-control bg-white"
                        :config="{ static: true }"
                        v-model="search.from_date"
                        :placeholder="$t('globalTrans.select_date')"
                      ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="6" md="4" lg="4">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.to_date')"
                    label-for="to_date"
                    >
                      <flat-pickr class="form-control bg-white"
                        :config="{ static: true }"
                        v-model="search.to_date"
                        :placeholder="$t('globalTrans.select_date')"
                      ></flat-pickr>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="2">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user.movement_summary') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns"  aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(date)="data">
                      {{ data.item.date | dateFormat }}
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <a v-if="data.item.attachment" :href="authServiceBaseUrl + 'download-attachment?file=' + data.item.attachment">
                         <i class="ri-download-cloud-line"></i>
                      </a>
                      <a v-else>N/A</a>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" :title="$t('globalTrans.view')" v-b-modal.modal-5 size="sm" @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" :title="$t('globalTrans.edit')" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Details :item="item"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import FormV from './Form'
import Details from './Details'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { movementList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, Details
  },
  data () {
    return {
      search: {
        from_date: '',
        to_date: ''
      },
      rows: [],
      item: '',
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      authServiceBaseUrl: authServiceBaseUrl
    }
  },
  computed: {
    formTitle () {
      return (this.editItemId === 0) ? this.$t('user.movement_summary') + ' ' + this.$t('globalTrans.entry') : this.$t('user.movement_summary') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('globalTrans.name'), class: 'text-center' },
        { label: this.$t('globalTrans.designation'), class: 'text-center' },
        { label: this.$t('globalTrans.date'), class: 'text-center' },
        { label: this.$t('globalTrans.location'), class: 'text-center' },
        { label: this.$t('globalTrans.attachment'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'designation_bn' },
          { key: 'date' },
          { key: 'location_bn' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name_en' },
          { key: 'designation_en' },
          { key: 'date' },
          { key: 'location_en' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  async created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (editItem) {
      this.item = editItem
    },
    async loadData () {
      const params = Object.assign({}, this.search, { user_id: this.authUser.user_id, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(authServiceBaseUrl, movementList, params)

      if (result.success) {
        this.$store.dispatch('setList', this.getRelationalData(result.data.data))
        this.paginationData(result.data)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      return data.map(item => {
        const desg = this.$store.state.commonObj.designationList.find(el => el.value === item.designation_id)
        return Object.assign(item, {
          name_en: typeof item.user !== 'undefined' ? item.user.name : '',
          name_bn: typeof item.user !== 'undefined' ? item.user.name_bn : '',
          designation_en: typeof desg !== 'undefined' ? desg.text_en : '',
          designation_bn: typeof desg !== 'undefined' ? desg.text_bn : ''
        })
      })
    }
  }
}
</script>
